// Generated by Framer (f318921)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import SocialIconWithLink from "https://framerusercontent.com/modules/1os2wntCYhs5JztZzKt2/JtG729FoaOpTkeZ90qlG/GPrjCxtjr.js";
const SocialIconWithLinkFonts = getFonts(SocialIconWithLink);

const serializationHash = "framer-0Hd5Z"

const variantClassNames = {qZPeJkTND: "framer-v-2xt9vc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({_01Icon, _01Link, _02Icon, _02Link, _03Icon, _03Link, height, id, width, ...props}) => { return {...props, d2mQNKHsU: _03Icon ?? props.d2mQNKHsU, eOCbewjqH: _01Link ?? props.eOCbewjqH, FRqngJApY: _03Link ?? props.FRqngJApY, LlzZZ5z1T: _02Link ?? props.LlzZZ5z1T, pXdFv66Ja: _02Icon ?? props.pXdFv66Ja, SU1b7DlOD: _01Icon ?? props.SU1b7DlOD} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;_01Icon?: {src: string; srcSet?: string};_01Link?: string;_02Icon?: {src: string; srcSet?: string};_02Link?: string;_03Icon?: {src: string; srcSet?: string};_03Link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, SU1b7DlOD, eOCbewjqH, pXdFv66Ja, LlzZZ5z1T, d2mQNKHsU, FRqngJApY, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "qZPeJkTND", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-2xt9vc", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"qZPeJkTND"} ref={ref ?? ref1} style={{...style}}><ComponentViewportProvider height={22} width={"22px"} y={((componentViewport?.y || 0) + (0 + ((((componentViewport?.height || 200) - 0) - 22) / 2)))}><motion.div className={"framer-4hjuhq-container"} layoutDependency={layoutDependency} layoutId={"vTPybVwro-container"}><SocialIconWithLink height={"100%"} id={"vTPybVwro"} layoutId={"vTPybVwro"} style={{height: "100%", width: "100%"}} u7smZm8vG={toResponsiveImage(SU1b7DlOD)} width={"100%"} ws1WndZLy={eOCbewjqH}/></motion.div></ComponentViewportProvider><ComponentViewportProvider height={22} width={"22px"} y={((componentViewport?.y || 0) + (0 + ((((componentViewport?.height || 200) - 0) - 22) / 2)))}><motion.div className={"framer-4bv808-container"} layoutDependency={layoutDependency} layoutId={"fTkZ8UDkO-container"}><SocialIconWithLink height={"100%"} id={"fTkZ8UDkO"} layoutId={"fTkZ8UDkO"} style={{height: "100%", width: "100%"}} u7smZm8vG={toResponsiveImage(pXdFv66Ja)} width={"100%"} ws1WndZLy={LlzZZ5z1T}/></motion.div></ComponentViewportProvider><ComponentViewportProvider height={22} width={"22px"} y={((componentViewport?.y || 0) + (0 + ((((componentViewport?.height || 200) - 0) - 22) / 2)))}><motion.div className={"framer-lc0hik-container"} layoutDependency={layoutDependency} layoutId={"V2vwGHHg2-container"}><SocialIconWithLink height={"100%"} id={"V2vwGHHg2"} layoutId={"V2vwGHHg2"} style={{height: "100%", width: "100%"}} u7smZm8vG={toResponsiveImage(d2mQNKHsU)} width={"100%"} ws1WndZLy={FRqngJApY}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-0Hd5Z.framer-1s6qmpq, .framer-0Hd5Z .framer-1s6qmpq { display: block; }", ".framer-0Hd5Z.framer-2xt9vc { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-0Hd5Z .framer-4hjuhq-container, .framer-0Hd5Z .framer-4bv808-container, .framer-0Hd5Z .framer-lc0hik-container { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 22px); position: relative; width: 22px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-0Hd5Z.framer-2xt9vc { gap: 0px; } .framer-0Hd5Z.framer-2xt9vc > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-0Hd5Z.framer-2xt9vc > :first-child { margin-left: 0px; } .framer-0Hd5Z.framer-2xt9vc > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 200
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"SU1b7DlOD":"_01Icon","eOCbewjqH":"_01Link","pXdFv66Ja":"_02Icon","LlzZZ5z1T":"_02Link","d2mQNKHsU":"_03Icon","FRqngJApY":"_03Link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerZRJZeMRh8: React.ComponentType<Props> = withCSS(Component, css, "framer-0Hd5Z") as typeof Component;
export default FramerZRJZeMRh8;

FramerZRJZeMRh8.displayName = "Social icons";

FramerZRJZeMRh8.defaultProps = {height: 200, width: 200};

addPropertyControls(FramerZRJZeMRh8, {SU1b7DlOD: {title: "01 Icon", type: ControlType.ResponsiveImage}, eOCbewjqH: {title: "01 Link", type: ControlType.Link}, pXdFv66Ja: {title: "02 Icon", type: ControlType.ResponsiveImage}, LlzZZ5z1T: {title: "02 Link", type: ControlType.Link}, d2mQNKHsU: {title: "03 Icon", type: ControlType.ResponsiveImage}, FRqngJApY: {title: "03 Link", type: ControlType.Link}} as any)

addFonts(FramerZRJZeMRh8, [{explicitInter: true, fonts: []}, ...SocialIconWithLinkFonts], {supportsExplicitInterCodegen: true})